import './Logo.css';

function Logo(props) {
    return(
        <div className="logo">
            <a href={props.href}>
                <img src={props.src} alt={props.alt_text}/>
            </a>
        </div>
    );
}

export default Logo;