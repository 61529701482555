import './Name.css';

function Name(props) {
    return (
        <div className="name">
            {props.name}
        </div>
    );
}

export default Name;