import Name from './components/Name';
import Logo from './components/Logo';
import './App.css';
import GithubLogo from './images/github-logo.png';
import LinkedInLogo from './images/linkedin-logo.png';

const NAMES = ['BLAKE', 'KAPLAN'];
const LOGOS = [
  {
    href: 'https://github.com/blakejkaplan',
    src: GithubLogo,
    alt_text: 'Github logo',
  },
  {
    href: 'https://www.linkedin.com/in/blakejkaplan',
    src: LinkedInLogo,
    alt_text: 'Linkedin logo',
  }
];

function App() {

  return (
    <div className="main">
      <div className="names">
        {NAMES.map((name) => <Name name={name} />)}
      </div>
      <div className="logos">
        {LOGOS.map(({href, src, alt_text}) => <Logo href={href} src={src} alt_text={alt_text} />)}
      </div>
    </div>
  );
}

export default App;
